import styled from '@emotion/styled';
import React from 'react';

import { useTheme } from '../ThemeContext';

interface Props {
  author: string;
  source: string;
  sourceUrl: string;
}

const Blockquote: React.FC<Props> = ({
  author,
  children,
  source,
  sourceUrl,
}) => {
  const { colorMode } = useTheme();
  const cite = <cite title={source}>{source}</cite>;

  const Wrapper = styled.figure`
    position: relative;
    padding: 1.5rem;
    border-radius: 0 0.375rem 0.375rem 0.125rem;
    border-left: 4px solid var(--color-info);
    background-color: var(--color-info-background);
    margin: 0;
    margin-bottom: 2rem !important;

    a {
      color: ${colorMode === 'dark' ? 'white' : 'black'};
    }

    @media (min-width: 1120px) {
      margin: 0 -1.5rem;
    }
  `;

  return (
    <Wrapper>
      <Quote>{children}</Quote>
      <Footer>
        {author && source && `${author} in `}
        {source && !sourceUrl && cite}
        {author && !source && sourceUrl && (
          <a href={sourceUrl} target="_blank">
            {author}
          </a>
        )}
        {!author && !source && sourceUrl && (
          <a href={sourceUrl} target="_blank">
            source
          </a>
        )}
        {source && sourceUrl && (
          <a href={sourceUrl} target="_blank">
            {cite}
          </a>
        )}
      </Footer>
    </Wrapper>
  );
};

const Footer = styled.figcaption`
  margin-top: 1rem;
  &:before {
    content: '— ';
  }
`;

const Quote = styled.blockquote`
  display: flex;
  flex-direction: column;
  & > * + * {
    margin-top: 1.5rem;
  }
`;

export default Blockquote;
